import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/Service.service';
import { review } from '../Models/models';

@Component({
  selector: 'app-Alltestimonies',
  templateUrl: './Alltestimonies.component.html',
  styleUrls: ['./Alltestimonies.component.scss']
})
export class AlltestimoniesComponent implements OnInit {
  Reviews: review[] = [];
  revieww: review = new review;
  constructor(private Servicios: ServiceService) { }

  ngOnInit() {
    this.Servicios.getReviews().subscribe((data: any) => {
      this.Reviews = data.message;
    },
    (err) => console.error(err));
  }

}
