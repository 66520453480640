import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment.prod';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private httpClient: HttpClient) {
  }

  getReviews() {
    const url = `${environment.api}GetReviews?`;
    return this.httpClient.get(url);
  }

  insertReview(name: string, message: string): Observable<any> {
    const url = `${environment.api}AddReview?name=` + name + "&message=" + message;
    // const params = new HttpParams()
    //   .append('typeName', obj.typeName);
    return this.httpClient.get(url);
  }

}
