<div class="container-fluid " style="padding-top:20px; padding-bottom: 40px;background-image: url('./assets/img/bg1.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-md-12 my-2 wow fadeInUp">
        <h1 class="text-center text-uppercase pb-4" style="font-family: 'Francois One', sans-serif; font-size:37px; color:#af1e46;">
          Respiratory Protection & Fit Training
        </h1>
      </div>
      <div class="col-md-6 wow fadeInUp text-justify">
          <p class="lead alert-danger px-2" style="width:100%; background:#006699 !important; color:white; border-radius:5px;">Course Length: 2 Hours </p>

          <p>This training  class is designed to inform you how to protect yourself using a respirator. Respirators protect workers against insufficient oxygen environments, harmful dusts, fogs, smokes, mists, gases, vapors, and sprays.<br><br> These hazards may cause cancer, lung impairment, other diseases, or death.

                  Being compliant with the OSHA Respiratory Protection Standard could avert hundreds of deaths and thousands of illnesses annually. <br><br>Medical evaluation procedures are there to protect 5 million workers that are required to wear respirators in 1.3 million workplaces throughout the United States. </p>
          <p class="lead alert-danger px-2" style="width:100%; background:#006699 !important; color:white; border-radius:5px;">Objetive </p>
<ul>
  <li>Become familiar with current OSHA standards for respiratory protection</li>
  <li>Know the purpose and use for respirators</li>
  <li>Identify different types of respirators</li>
  <li>Know the requirements for the use of respiratory protection</li>
</ul>
<p class="b text-justify">This training class is taught in accordance with the Occupational Health and Safety Administration Code of Federal Standards; 29 CFR 1910.134 Subpart I.</p>
      </div>
      <div class="col-md-6 wow fadeInUp">
<div id="carouselExampleControls" class="carouselwrapper carousel slide" data-ride="carousel">
<div class="carousel-inner">
<div class="carousel-item active">
<img class="d-block w-60 img-fluid" id="imgDesease" src="./assets/img/respiratory.jpg" alt="First slide" style="max-height:400px;">
</div>
<div class="carousel-item">
<img class="d-block w-60 img-fluid" id="imgDesease" src="./assets/img/594c7f1518c17f0a7e25fe2780845d5d.jpg" alt="Second slide" style="max-height:400px;">
</div>
</div>
<a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
<span class="carousel-control-prev-icon" aria-hidden="true"></span>
<span class="sr-only">Previous</span>
</a>
<a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
<span class="carousel-control-next-icon" aria-hidden="true"></span>
<span class="sr-only">Next</span>
</a>
</div>
</div>
    </div>
    <div class="row">
        <div class="col-md-12 wow fadeInUp">
              <a href="https://www.trainingsoftwaresupport.com/#/home/search?type=NF&month=1&location=NF&spanish=false" target="_Blank"><button class="btn btn-primary" style="font-size:20px; background:#af1e46 !important;"><i class="fas fa-phone-square"></i> Register for Respiratory Protection & Fit Training classes now!</button></a>
          </div>
    </div>
  </div>
</div>