<div class="container-fluid " id="allTestimoniesList"
  style="padding-top:20px; padding-bottom: 40px;background-image: url('.assets/img/bg1.jpg')">
  <div class="container">
    <div class="col-md-12 my-2 wow fadeInUp">
      <h1 class="text-center text-uppercase pb-4"
        style="font-family: 'Francois One', sans-serif; font-size:37px; color:#af1e46;">
        what customers think about us
      </h1>
    </div>
    <div class="row">
      <div class="col-md-12" style="font-size:18px;">
        <div *ngFor="let item of Reviews;">
          <p>"{{item.comment}}"<b> {{item.name}}</b></p>
        </div>

        <p>"By the way, I want to tell you that I have been taking CPR classes every other year for the last 30 years,
          and your class is the first class I have taken that I really learned and understood the processes. Your
          approach to the class in explaining how and why was fabulous and you should be very proud of your business! It
          is hard to keep people engaged and you had my attention the entire time. Both my friend and husband agree with
          me that you did an awesome job.!" <b>Donna R. Teeter</b></p>
        <p>"Thanks to their disease management education class, now my employees know how to take care of themselves and
          I can lead them to a healthy life as a
          company owner." <b>John L. Kirkpatrick</b></p>


        <p>"I like to always be prepared for any emergency and with this CPR class I now feel confident to be able to
          react to any situation" <b>Raul S. Clower</b></p>


        <p>"I am a warehouse manager and since my employees took the forklift class they are now better prepared to
          perform their job safely." <b>Terrance C. Callahan</b></p>

        <p>"Without a doubt the best CPR training class I have taken, I highly recommend it ..." <b>Sidney L. Flores</b>
        </p>
        <p> "A co-worker recommended MSI and I decided to take one of their first aid classes and I do not regret having
          done it, excellent class" <b>Todd C. Monroe</b></p>


        <p> "I found this company through the internet, I registered in its class of first aid and it was a good
          decision, excellent instructors and a very pleasant environment, I will certainly continue taking classs with
          them" <b>Raymond A. McRae</b></p>


        <p>"The instructor was very enthusiastic, easy to follow and everything was hands-on, I loved it! " <b>Jesus O.
            Sewell</b></p>

        <p>"I just wanted to take a minute to brag on one of your instructors. Brian was so knowledgeable very thorough
          and hands on. I really enjoyed being a part of his class. You can tell he is very passionate and loves to
          teach class. You don’t find that quality in very many people but he is certainly one of them. I honestly
          thought it was going to be a very boring long 4 hour class as it was my very first class. It was quite the
          opposite with him he made the class fun and exciting he is a great instructor anyone who gets him is a very
          lucky student!" <b>Care Dynamics - Tulsa Oklahoma</b></p>

        <p class="lead text-center bg-danger text-white p-2" style="width:100%; background:#af1e46 !important;">And many
          more!</p>
      </div>
    </div>
  </div>
</div>