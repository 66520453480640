<div class="container-fluid " style="padding-top:20px; padding-bottom: 40px;background-image: url('./assets/img/bg1.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-md-12 my-2 wow fadeInUp">
        <h1 class="text-center text-uppercase pb-4" style="font-family: 'Francois One', sans-serif; font-size:37px; color:#af1e46;">
          Bilingual Fork Lift Training
        </h1>
        <p class="text-center">The Forklift training class presents interactive instruction covering the following topical areas.</p>
      </div>
      <div class="col-md-3 wow fadeInUp">
          <p class="lead alert-danger px-2" style="width:100%; background:#006699 !important; color:white; border-radius:5px;"><b>STABILITY</b></p>
          <ul>
              <li>How a forklift works</li>
              <li>Rated capacity</li>
              <li>The stability triangle</li>
              <li>Testing the load</li>
          </ul>
          <p class="lead alert-danger px-2" style="width:100%; background:#006699 !important; color:white; border-radius:5px;"><b>INSPECTION</b></p>
          <ul>
              <li>Before start up: basic parts</li>
              <li>Before start up: battery operated trucks</li>
              <li>Before start up: LP trucks</li>
              <li>Before start up: fuel tanks</li>
              <li>After start up</li>
              <li>Inspection forms</li>
          </ul>
      </div>
      <div class="col-md-3 wow fadeInUp">
          <p class="lead alert-danger px-2" style="width:100%; background:#006699 !important; color:white; border-radius:5px;"><b>OPERATION</b></p>
          <ul>
              <li>Picking up a load</li>
              <li>Traveling: fork positioning</li>
              <li>Traveling: poor visibility </li>
              <li> Traveling: driving on inclines </li>
              <li> Traveling: turning </li>
              <li>Traveling: traffic rules </li>
              <li>Traveling: stopping</li>
              <li>Traveling: parking</li>
              <li>Putting down a load: basic procedures </li>
              <li>Putting down a load: stacking procedure</li>
          </ul>
      </div>
      <div class="col-md-6 mb-4 wow fadeInUp">
          <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active p-4">
                  <img class="d-block w-100 img-fluid" id="imgDesease" src="./assets/img/FEMA_-_37931_-_Meals_Ready_to_Eat_being_moved_by_fork_lift_in_a_Texas_warehouse.jpg" alt="First slide" width="100" height="400">
                </div>
                <div class="carousel-item p-4">
                    <img class="d-block w-100 img-fluid" id="imgDesease" src="./assets/img/2c13e082ce521044e5cbae8b4cc44cc3.jpg" alt="Third slide" width="100" height="400">
                  </div>
                   <div class="carousel-item p-4">
                    <img class="d-block w-100 img-fluid" id="imgDesease" src="./assets/img/8c4c2409cb4e55063b26ba50ede0692a.jpg" alt="Third slide" width="100" height="400">
                  </div>
                  <div class="carousel-item p-4">
                    <img class="d-block w-100 img-fluid" id="imgDesease" src="./assets/img/a4b069f215b430de2ab75611ea1cd0b0.jpg" alt="Third slide" width="100" height="400">
                  </div>
                  <div class="carousel-item p-4">
                    <img class="d-block w-100 img-fluid" id="imgDesease" src="./assets/img/a34e90aba992ac89fd87689d815d66a2.jpg" alt="Third slide" width="100" height="400">
                  </div>
              </div>
            </div>
      </div>
      <div class="col-md-12 wow fadeInUp p-0">
                  <a href="https://www.trainingsoftwaresupport.com/#/home/search?type=NF&month=1&location=NF&spanish=false" target="_Blank"><button class="btn btn-primary" style="font-size:20px; background:#af1e46 !important;"><i class="fas fa-phone-square"></i> Register for fork lift Training classes now!</button></a>
              </div>

    </div>
  </div>
</div>