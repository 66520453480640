<div class="container-fluid "
  style="padding-top:20px; padding-bottom: 40px;background-image: url('./assets/img/bg1.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-md-12 my-2 wow fadeInUp">
        <h1 class="text-center text-uppercase pb-4"
          style="font-family: 'Francois One', sans-serif; font-size:37px; color:#af1e46;">
          Disease Management Prevention & Education
        </h1>
      </div>
      <div class="col-md-6 my-2 wow fadeInUp text-justify">
        <p class=""><b>Healthcare</b> costs are on the rise, the best way to contain cost is to have an empolyee that is
          knowledgeable about disease prevention and avoid disease progression. Let us educate them to take ownership of
          their health to live a healthier life. <br><br>What are your current healthcare cost drivers? <br><br>Do your
          employees understand their health policy plan?
          <br><br>
          With disease management education, employees will live healthier lives; therefore decrease healthcare cost
          expenditures and increase your return on your investment.
        </p>
        <p>Bilingual Education Training Available by Registered Nurses and Health Educators.</p>
        <p class="lead p-2 alert-danger"
          style="width:275px !important; background:#006699 !important; color:white; border-radius:5px;">Health topics:
        </p>
        <ul>
          <li>Diabetes</li>
          <li>Coronary Artery Disease</li>
          <li>Heart Failure</li>
          <li>Hypertension</li>
          <li>Obesity</li>
          <li>COPD</li>
          <li>Asthma</li>
          <li>Heat Stress/Stroke</li>
        </ul>
      </div>

      <div class="col-md-6 justify-content-between wow fadeInUp">
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img class="d-block w-100 img-fluid" id="imgDesease"
                src="./assets/img/e162378de1c3a5fdb9d6213cfc3167f8.jpg" alt="First slide" style="max-height:420px;">
            </div>
            <div class="carousel-item">
              <img class="d-block w-100 img-fluid" id="imgDesease"
                src="./assets/img/15407-a-man-and-woman-practicing-yoga-in-a-fitness-center-pv.jpg" alt="Second slide"
                style="max-height:420px;">
            </div>
            <div class="carousel-item">
              <img class="d-block w-100 img-fluid" id="imgDesease"
                src="./assets/img/e0df9a160745db98c15cbc405a7cb212.jpg" alt="Third slide" style="max-height:420px;">
            </div>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
          <div class="col-md-12 justify-content-between wow fadeInUp my-3">
            <p class="bg- p-2 text-white text-center lead"
              style="background:#006699 !important; color:white; border-radius:5px;">Onsite Trainings available at your
              location!</p>
            <p class="bg- p-2 text-center text-white lead"
              style=" background:#006699 !important; color:white; border-radius:5px;">Special Discount of up to 3-4
              health topics!</p>
          </div>
        </div>
      </div>
      <div class="col-md-12 text-center wow fadeInUp p-0">
        <a href="https://www.trainingsoftwaresupport.com/#/home/search?type=NF&month=1&location=NF&spanish=false" target="_Blank"><button class="btn btn-primary"
            style="font-size:20px; background:#af1e46 !important;"><i class="fas fa-phone-square"></i> Register for
            Disease Management Prevention & Education Training classes now!</button></a>
      </div>
    </div>
  </div>
</div>