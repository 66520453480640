import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { TemplateRef } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class headerComponent {
  constructor(private router: Router, private modalService: NgbModal) {}

  title = 'Website';
  EstatusAsociacion = '';
  Association: string = '';
  RespuestaAsociacion: boolean = false;
  name: string = '';
  email: string = '';


  ngAfterViewInit(): void {
    const boton = document.getElementById('miBoton');
    if (boton) {
      boton.click();
    }
  }

  
  VerificarAssociation(){
    if (this.Association == 'MSI2021') {
      window.location.href='https://www.trainingsoftwaresupport.com/#/home/search?type=NF&month=1&location=NF&spanish=false/';
    }else{
      this.RespuestaAsociacion = true;
      this.EstatusAsociacion = 'Wrong Id Code';
    }
  }

  GoToCertificatePayment() {
    if(this.name != '' && this.email != ''){
      window.location.href='https://payments.trainingsoftwaresupport.com/?typePayment=certificate&from=' + this.name + '&toEmail=' + this.email;
    }
    else{
      Swal.fire(
        'Attention',
        'You must write your name and email',
        'info'
      )
    }
  }
}

