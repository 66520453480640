<div class="container-fluid "
  style="padding-top:20px; padding-bottom: 40px;background-image: url('./assets/img/bg1.jpg')">
  <div class="container">
    <div class="row">
      <div class="col-md-12 my-4">
        <h3 class="text-center text-uppercase "
          style="font-family: 'Francois One', sans-serif; font-size:37px; color:#af1e46;">Enroll In A Course Today!</h3>
      </div>
    </div>
    <div class="row p-0">
      <div class="col-md-4 p-4 wow fadeInUp">
        <img class="" src="./assets/img/shutterstock_1012548829-min.jpg" alt="" width="100%" height="200px;">
        <div class="shadow-sm text-white p-4 text-center rounded-bottom"
          style="background:#1f8cc2; min-height:300px !important;">
          <h3 class="text-left bg-">OSHA Safety Training</h3>
          <p class="text-left">The course is a comprehensive safety program designed for anyone involved in the
            construction industry. Specifically devised for safety directors, foremen and managers.
          </p>
          <a (click)="osha()"><button type="button" class="btn text-center"
              style="color:white !important;font-size:15px !important; background:#af1e46;"><i
                class="fas fa-info-circle"></i> More Details</button></a>
        </div>
      </div>
      <!--
      <div class="col-md-4 p-4 wow fadeInUp ">
        <img class="" src="./assets/img/b28b425063ce12f3ee9d2cd764cacd77.jpg" alt="" width="100%" height="200px;">
        <div class="shadow-sm text-white p-4 text-center rounded-bottom"
          style="background:#1f8cc2; min-height:300px !important;">
          <h3 class="text-left bg-">Respiratory Training</h3>
          <p class="text-left">This course is designed to inform you how to protect yourself using a respirator.
            Respirators protect workers against insufficient oxygen environments, fogs, smokes, etc.
          </p>
          <a (click)="respiratory()"><button type="button" class="btn text-center"
              style="color:white !important;font-size:15px !important; background:#af1e46;"><i
                class="fas fa-info-circle"></i> More Details</button></a>
        </div>
      </div>-->
      <div class="col-md-4 p-4 pt-0 wow fadeInUp">
        <img class="" src="./assets/img/shutterstock_472075360-min.jpg" alt="" width="100%" height="200px;">
        <div class="shadow-sm text-white p-4 text-center rounded-bottom"
          style="background:#1f8cc2; min-height:300px !important;">
          <h3 class="text-left bg-">CPR & First Aid</h3>
          <p class="text-left">We offer many certified and approved training options to meet the needs in any industry.
            Courses are available at your place of business, home, or a meeting place convenient to all.
          </p>
          <a (click)="cpr()"><button type="button" class="btn text-center "
              style="color:white !important;font-size:15px !important; background:#af1e46;"><i
                class="fas fa-info-circle"></i> More Details</button></a>
        </div>
      </div>
      <div class="col-md-4 p-4 wow fadeInUp">
        <img class="" src="./assets/img/73300924-33d1-4945-b9da-4a603158b5a4-apr18-safety-blood-640x360.jpg" alt=""
          width="100%" height="200px;">
        <div class="shadow-sm text-white p-4 text-center rounded-bottom"
          style="background:#1f8cc2; min-height:350px !important;">
          <h3 class="text-left bg-">Bloodborne Pathogens</h3>
          <p class="text-left" style="min-height:180px !important;">This two hour course provides students with basic
            understanding of bloodborne pathogens, modes of transmission, methods of prevention as well as other related
            information including Hepatitis B, Hepatitis C and HIV/AIDS.
          </p>
          <a (click)="bloodborne()"><button type="button" class="btn text-center"
              style="color:white !important;font-size:15px !important; background:#af1e46;"><i
                class="fas fa-info-circle"></i> More Details</button></a>
        </div>
      </div>
      <div class="col-md-4 p-4 wow fadeInUp ">
        <img class="" src="./assets/img/830e1532eb64e18b2e7f2dfefefd8b1f.jpg" alt="" width="100%" height="200px;">
        <div class="shadow-sm text-white p-4 text-center rounded-bottom"
          style="background:#1f8cc2; min-height:350px !important;">
          <h3 class="text-left bg-">Bilingual Fork Lift Training</h3>
          <p class="text-left" style="min-height:180px !important;">The Forklift Course presents interactive instruction
            covering the following topical areas of stability, inspection and operation.
          </p>
          <a (click)="forklift()"><button type="button" class="btn text-center"
              style="color:white !important;font-size:15px !important; background:#af1e46;"><i
                class="fas fa-info-circle"></i> More Details</button></a>
        </div>
      </div>
      
    </div>
  </div>
</div>