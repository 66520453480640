import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-Bloodborne',
  templateUrl: './Bloodborne.component.html',
  styleUrls: ['./Bloodborne.component.css']
})
export class BloodborneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
