<div class="container-fluid " style="background:#006699;">
  <div class="container " style="font-size:16px;">
    <div class="row pt-2 text-white">
      <div class="col-md-3 wow fadeInUp" id="links" style="visibility: visible; animation-name: fadeInUp;">
        <h3 class="py-2 pt-4 text-uppercase" style="font-family: 'Francois One', sans-serif; font-size:25px;">Links</h3>
        <p class="">
          <a (click)="home()" class="footerMenuA text-white" style="font-size:17px !important;">
            <i class="fas fa-angle-right"></i> Home</a>
        </p>
        <p>
          <a (click)="allCourses()" class="footerMenuA text-white" style="font-size:17px !important;">
            <i class="fas fa-angle-right"></i> Training Classes Offered</a>
        </p>
        <p>
          <a (click)="contact()" class="footerMenuA text-white" style="font-size:17px !important;">
            <i class="fas fa-angle-right"></i> Contact Us</a>
        </p>
        <p>
          <a href="https://trainingsoftwaresupport.com" class="footerMenuA text-white"
            style="font-size:17px !important;">
            <i class="fas fa-angle-right"></i> Register Now</a>
        </p>
      </div>
      <div class="col-md-6">
        <h3 class="py-2 pt-4 text-justify text-uppercase text-"
          style="font-family: 'Francois One', sans-serif; font-size:25px;">CPR &amp; First Aid Training Institute </h3>
        <p style="font-size:16px !important; max-width:470px;" class="text-capitalize text-justify">CPR &amp; First Aid
          Training Institute has several advantages when it comes to providing training classes. CPR &amp; First Aid
          Training Institute is among the most experienced first aid and safety training organizations in Oklahoma. </p>
      </div>

      <div class="col-md-3">
        <h3 class="pb-2 pt-4 text-uppercase" style="font-family: 'Francois One', sans-serif; font-size:25px;">Contact
        </h3>
        <p class="text-justify" style="font-size:17px;">
          If you have any questions please let us know through our contact form by clicking on the button below.
        </p>
        <a (click)="contact()" href="contact#contacto"><button type="button" class="btn btn-danger shadow"
            style="font-size:16px;width:100%; margin:0px !important; background: #B22222 !important;"><i
              class="fas fa-envelope"></i> Contact Us</button></a>
      </div>
      <div class="col-md-2">

      </div>
    </div>
    <div class="row py-5">
      <div class="col-md-12 text-white text-center wow fadeIn" style="visibility: visible; animation-name: fadeIn;">
        <a href="http://trainingsoftwaresupport.com"><button type="button" class="btn btn-primary shadow"
            style="padding:15px 60px; font-size:16px; background:#1f8cc2; color:white;"><i
              class="fas fa-sign-in-alt"></i> Register Now!</button></a>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid" style="background:#1f8cc2;">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center text-white">
        <p class="pt-3">Website Built by
          <a href="https://hancarsoft.com/" style="color:white;">HancarSoft</a>
        </p>
      </div>
    </div>
  </div>
</div>