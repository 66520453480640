<div class="container-fluid " style="padding-bottom: 40px; " id="contacto">
  <div class="container bg-white ">
    <div class="row justify-content-center">
      <div class="col-md-12 mt-4">
        <h2 class="text-center text-uppercase"
          style="font-family: 'Francois One', sans-serif; font-size:37px; color:#af1e46;">
          Get In Touch</h2>
        <hr>
        <div class="row">
          <div class="col-md-6 text-center">
            <i class="fas fa-mobile-alt text-warning mt-5 mb-3" style="font-size:75px; color: #B22222 !important;"> </i><br>
            <p class="leade">Phone:</p>
            <p class="lead">1-888-261-8813</p>
          </div>
          <div class="col-md-6 text-center">
            <i class="fas fa-envelope text-warning mt-5 mb-3" style="font-size:75px; color: #B22222 !important;"> </i><br>
            <p class="leade">Email:</p>
            <a class="lead txtEmail" href="mailto:iv@cprandfirstaidtraininginstitute.com">iv@cprandfirstaidtraininginstitute.com</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>