import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-Allcourses',
  templateUrl: './Allcourses.component.html',
  styleUrls: ['./Allcourses.component.scss']
})
export class AllcoursesComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
  Contacto(){
    this.router.navigate([`../contact`]);
  }
  Disease(){
    this.router.navigate([`../disease`]);
  }
  forklift(){
    this.router.navigate([`../forklift`]);
  }
  bloodborne(){
    this.router.navigate([`../bloodborne`]);
  }
  osha(){
    this.router.navigate([`../osha`]);
  }
  cpr(){
    this.router.navigate([`../cpr`]);
  }
  respiratory(){
    this.router.navigate([`../respiratory`]);
  }
}
