import { Component, OnInit, ViewChild } from '@angular/core';
import { headerComponent } from '../Header/header.component';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ServiceService } from 'src/app/Service.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { review } from '../Models/models';
@Component({
  selector: 'app-Inicio',
  templateUrl: './Inicio.component.html',
  styleUrls: ['./Inicio.component.css']
})
export class InicioComponent implements OnInit {
  closeResult = '';
name = '';
keyword = '';
message = '';
  constructor(private modalService: NgbModal,private router: Router, private Servicios: ServiceService) {}

  ngOnInit() {
  }
  Contacto(){
    this.router.navigate([`../contact`]);
  }
  Disease(){
    this.router.navigate([`../disease`]);
  }
  allCourses(){
    this.router.navigate([`../allcourses`]);
  }
  allTestimonies(){
    this.router.navigate([`../alltestimonies`]);
  }
  osha(){
    this.router.navigate([`../osha`]);
  }
  cpr(){
    this.router.navigate([`../cpr`]);
  }
  respiratory(){
    this.router.navigate([`../respiratory`]);
  }
  forkLift(){
    this.router.navigate([`../forklift`]);
  }
  gotoregister(){
    window.location.href = 'https://www.trainingsoftwaresupport.com/#/home/search?type=NF&month=1&location=NF&spanish=false/';
  }
  SendReview(){
    if(this.keyword == 'CFATI'){
      this.Servicios.insertReview(this.name, this.message).subscribe(any => {
        if (any.success === "Ok") {
          Swal.fire('Thank You', 'Review sent!', 'success');
        } else {
          Swal.fire('Information', 'There was a problem sending your review!', 'info');
        }
      },
      (err) => console.error(err));
    }else{
      Swal.fire('Error', 'Wrong keyword!', 'error');
    }
    
  }
}
function res(res: any, arg1: (any: any) => void, arg2: (err: any) => void) {
  throw new Error('Function not implemented.');
}

