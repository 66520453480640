<div class="container-fluid " style="padding-top:20px; padding-bottom: 40px;background-image: url('./assets/img/bg1.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-md-12 my-2 wow fadeInUp">
        <h1 class="text-center text-uppercase pb-4" style="font-family: 'Francois One', sans-serif; font-size:37px; color:#af1e46;">
          Bloodborne Pathogens
        </h1>
      </div>
      <div class="col-md-6 wow fadeInUp">
          <p  class="lead alert-danger px-2" style="width:100%; background:#006699 !important; color:white; border-radius:5px;">Bloodborne Pathogens Basic Class</p>

          <p class="text-justify">This 2-hour training class provides students with basic understanding of bloodborne pathogens, modes of transmission, methods of prevention as well as other related information including Hepatitis B, Hepatitis C and HIV/AIDS. We follow the OSHA Standard established in 2001.(29 CFR 1910.1030) to protect workers from this risk. Students will obtain a bloodborn pathogens certificate at the completion of this class. </p>
          <p  class="lead alert-danger px-2" style="width:100%; background:#006699 !important; color:white; border-radius:5px;">Bloodborne Pathogens Trainer & Exposure Control Class</p>

          <p class="text-justify">This one-day training class provides students with the
opportunity to develop a Bloodborne Pathogens
Exposure Control Plan. Target Audience: program
administrators, managers, or other personnel
designated with the responsibility of developing a
Bloodborne Pathogens Exposure Control Plan.</p>
      </div>
      <div class="col-md-5 wow fadeInUp ml-4" id="carouselBloodborne">
      <div id="carouselExampleControls" class="carouselwrapper carousel slide" data-ride="carousel">
<div id="bloodborne" class="carousel-inner" style="max-width:420px !important; max-height:340px;">
<div id="bloodborne" class="carousel-item active" style="max-width:420px; max-height:340px;">
<img class="d-block w-60 img-fluid" id="" src="./assets/img/1341688_9bff_2.jpg" alt="First slide" style="max-width:420px !important; max-height:340px;">
</div> 
<div id="bloodborne" class="carousel-item" style="max-width:420px !important; max-height:340px;">
<img class="d-block w-60 img-fluid" id="" src="./assets/img/73300924-33d1-4945-b9da-4a603158b5a4-apr18-safety-blood-640x360.jpg" alt="First slide" style="max-width:420px !important; max-height:340px;">
</div>
<div id="bloodborne" class="carousel-item" style="max-width:420px !important; max-height:340px;">
<img class="d-block w-60 img-fluid" id="" src="./assets/img/transmission-ports-for-bbp.jpg" alt="Second slide" style="max-width:360px !important; max-height:340px;">
</div>
<!--<div id="bloodborne" class="carousel-item active" style="width:360px; border:20px solid #af1e46; height:340px;">
<img class="d-block w-60" id="" src="img/blood1.jpg" alt="First slide" style="width:320px; height:300px; float:left;">
</div>
<div id="bloodborne" class="carousel-item" style="width:360px !important; border:20px solid #af1e46 ; height:340px;">
<img class="d-block w-60" id="" src="img/blood2.jpg" alt="First slide" style="width:320px;height:300px;">
</div>
<div id="bloodborne" class="carousel-item" style="width:360px !important; border:20px solid #af1e46 ; height:340px;">
<img class="d-block w-60" id="" src="img/blood3.jpg" alt="Second slide" style="width:320px;height:300px;">
</div>
<div id="bloodborne" class="carousel-item" style="width:360px !important; border:20px solid #af1e46 ; height:340px;">
<img class="d-block w-60" id="" src="img/blood4.jpg" alt="Third slide" style="width:320px;height:300px;">
</div>-->
</div>
<a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
<span class="carousel-control-prev-icon" aria-hidden="true"></span>
<span class="sr-only">Previous</span>
</a>
<a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
<span class="carousel-control-next-icon" aria-hidden="true"></span>
<span class="sr-only">Next</span>
</a>
</div>

      </div>
  </div>
  <div class="col-md-12 wow fadeInUp p-0 text-center">
              <a href="https://www.trainingsoftwaresupport.com/#/home/search?type=NF&month=1&location=NF&spanish=false" target="_Blank"><button class="btn btn-primary" style="font-size:20px; background:#af1e46 !important;"><i class="fas fa-phone-square"></i> Register for bloodborne pathogens Training classes now!</button></a>
          </div>
  </div>
</div>