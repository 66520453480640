import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { footerComponent } from './Modulos/Footer/footer.component';
import { headerComponent } from './Modulos/Header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InicioComponent } from './Modulos/Inicio/Inicio.component';
import { HttpClientModule } from '@angular/common/http';
import { AlltestimoniesComponent } from './Modulos/Alltestimonies/Alltestimonies.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    AppComponent,
    headerComponent,
    footerComponent,
    InicioComponent,
    AlltestimoniesComponent

  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    ModalModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
