<div id="carouselExampleIndicators" class="sliderWeb carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    </ol>
    <div class="carousel-inner">
        <div class="carousel-item active">
            <div style="position:absolute; text-align:center; width:100%; top:80px;">
                <h1 class="text-white text-uppercase pl-4  animated bounceInUp"
                    style="letter-spacing: 5px; font-weight:bold;font-size:43px;  font-family: 'Roboto', sans-serif !important;">
                    <b> we Believe In Prevention
                        <br />We Believe In Saving Lives!</b>
                </h1>
                <br>
                <p style="margin-left:25%; width:50%;"
                    class="text-center text-white text-uppercase animated bounceInDown"
                    style="letter-spacing: 2px; font-size:20px; ">LEARN TO SAVE LIVES IN ANY OF OUR LIFE-SAVING
                    TRAINING<br>
                    CLASSES TAUGHT BY CERTIFIED INSTRUCTORS!</p>
                <a routerLink="contact" (click)="Contacto()" class="text-white"><button
                        class="btn lead px-4 mt-4 animated bounceInUp"
                        style="margin-left:30px; font-size:17px;padding: 10px 30px; background:#1f8cc2; color: white !important;">Contact
                        Us
                        <i class="fas fa-arrow-alt-circle-right"></i>
                    </button></a>
            </div>
            <img class="d-block w-100 img-fluid" src="./assets/img/banner.jpg" alt="First slide" height="450px;">

        </div>
        <div class="carousel-item">
            <div style="position:absolute; left:50%;">
                <div style="width:200px; position:absolute;left:70px;top:10px; border-radius:-20px;"
                    class="cuadroSlider p-4">
                    <!--<img src="img/circulo.png" width="530px" style="">-->
                    <img src="./assets/img/fondo2.png" width="530px">

                </div>
                <div class="leftSidebar" style="position:relative; top:80px; left:-60%;">

                    <h1 class="text-white text-uppercase pl-4  animated bounceInUp"
                        style="width:900px;letter-spacing: 5px; font-weight:bold;font-size:30px;  font-family: 'Roboto', sans-serif;">
                        Help Your Employees Live Healthy And Safe Lives</h1><br>
                    <p class="text-white text-uppercase animated bounceInDown pl-4"
                        style="font-size:15px;  width:700px;">WITH DESEASE MANAGEMENT EDUCATION, EMPLOYEES WILL WORK
                        SAFER AND LIVE HEALTHIER LIVES; THEREFORE DECREASING HEALTHCARE COSTS AND EXPENDITURES, AND
                        INCREASING YOUR RETURN ON YOUR INVESTMENT.</p>
                    <a routerLink="disease" (click)="Disease()" class="text-white btn lead mt-4  animated bounceInUp"
                        style="margin-left:30px; font-size:15px;padding: 10px 30px; background:#1f8cc2; color:white; z-index:9999;">More
                        Info
                        <i class="fas fa-arrow-alt-circle-right"></i>
                    </a>
                </div>
            </div>
            <img class="d-block w-100" src="./assets/img/fondo.png" alt="Second slide"
                height="450px; background:#B22222!important;">
        </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
</div>
<!-- Cursos -->
<div class="container-fluid py-4" style="padding-top:60px; background-image: url('./assets/img/bg1.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12 wow fadeInUp">
                <p class="text-center text-uppercase pt-4 pb-2 "
                    style="font-size: 33px;font-family: 'Francois One', sans-serif; font-size:50px; color:#006699;">
                    <b style="color:#006699;">learn how to save lives <mark
                            style="background:none; color:#B22222;">anytime, anywhere</mark></b>
                </p>
                <p class="lead text-uppercase text-center"
                    style="font-family: 'Francois One', sans-serif; font-size:35px; color:#006699;">
                    SIGN UP AND REGISTER FOR A TRAINING CLASS WITH US!
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 p-4 wow fadeInUp">
                <img class="" src="./assets/img/shutterstock_472075360-min.jpg" alt="" width="100%" height="200px;">
                <div class="shadow-sm text-white p-4 text-center rounded-bottom" style="background:#1f8cc2;">
                    <h3 class="text-center bg-">CPR & First Aid</h3>
                    <p class="text-left">We offer many certified and approved training options to meet the needs in any
                        industry. This training class is available at your place of business, home, or a meeting place
                        convenient to all.
                    </p>
                    <a (click)="cpr()"><button type="button" class="btn text-center "
                            style="font-size:15px !important; background:#af1e46; color: white !important;"><i
                                class="fas fa-info-circle"></i>
                            More Details</button></a>
                </div>
            </div>
            <div class="col-md-4 p-4 wow fadeInUp">
                <img class="" src="./assets/img/shutterstock_1012548829-min.jpg" alt="" width="100%" height="200px;">
                <div class="shadow-sm text-white p-4 text-center rounded-bottom" style="background:#1f8cc2;">
                    <h3 class="text-center bg-">OSHA Safety Training</h3>
                    <p class="text-left" style="font-size:15.5px;">This training class is a comprehensive safety program
                        designed for anyone involved in the construction industry. Specifically devised for safety
                        directors, foremen and managers.
                    </p>
                    <a (click)="osha()"><button type="button" class="btn text-center"
                            style="font-size:15px !important; background:#af1e46; color: white !important;"><i
                                class="fas fa-info-circle"></i>
                            More Details</button></a>
                </div>
            </div>
            <div class="col-md-4 p-4 wow fadeInUp ">
                <img class="" src="./assets/img/FEMA_-_37931_-_Meals_Ready_to_Eat_being_moved_by_fork_lift_in_a_Texas_warehouse.jpg" alt="" width="100%" height="200px;">
                <div class="shadow-sm text-white p-4 text-center rounded-bottom" style="background:#1f8cc2;">
                    <h3 class="text-center bg-">Bilingual Fork Lift Training</h3>
                    <p class="text-left" style="font-size:15.5px;">The Forklift Course presents interactive instruction
                        covering the following topical areas of stability, inspection and operation.
                    </p>
                    <a (click)="forkLift()"><button type="button" class="btn text-center"
                            style="font-size:15px !important; background:#af1e46; color: white !important;"><i
                                class="fas fa-info-circle"></i>
                            More Details</button></a>
                </div>
            </div>
        </div>
        <div class="col-md-12 text-center">
            <a (click)="allCourses()"><button type="button" class="my-4 btn text-center text-uppercase  wow flipInY "
                    style=" background:#af1e46 ;padding:15px 60px; font-size: 18px; color: white !important;"> <i
                        class="fas fa-eye"></i> VIEW
                    ALL CLASSES OFFERED</button></a>
        </div>
    </div>
</div>
<!--Payment Info-->
<div id="paymentInfo" class="container-fluid text-white" style="background:#af1e46;">
    <div class="container">
        <div class="row text-uppercase  wow fadeInUp">
            <div class="col-md-4 pt-4">
                <h3 (click)="gotoregister()">Sign Up</h3>
                <p class="text-justify" style="font-size:15.5px; max-width:300px;">REGISTER FOR THE TRAINING CLASS YOU
                    WANT TO ATTEND</p>
                <i id="flechaDerecha" style="position:absolute; margin-top:-90px; font-size:0px; right:-5px;"
                    class="far fa-star mx-2"></i>
            </div>
            <div id="flechaAbajo"  class="col-md-12 text-center m-0" style="display:none;">
                <i class="fas fa-sort-down"  style="font-size:0px;"></i>
            </div>
            <div class="col-md-4 pt-4">
                <h3 (click)="gotoregister()">Pay</h3>
                <p class="text-justify" style="font-size:15.5px; max-width:300px;">Use the payment method of your
                    preference</p>
                <i id="flechaDerecha2" style="position:absolute; margin-top:-90px; font-size:0px; right:-5px;"
                    class="fas fa-caret-right mx-2"></i>
            </div>
            <div id="flechaAbajo2" class="col-md-12 text-center m-0" style="display:none;">
                <i class="fas fa-sort-down"  style="font-size:0px;"></i>
            </div>
            <div class="col-md-4 pt-4">
                <h3 (click)="gotoregister()">Have fun learning</h3>
                <p class="text-justify" style="font-size:16px;">SIMPLY ARRIVE AT THE TIME AND LOCATION YOUR CLASS IS
                    BEING
                    TAUGHT AND START LEARNING TO SAVE LIVES!
                </p>
            </div>
        </div>
    </div>
</div>
<!-- About MSI -->
<div class="container-fluid py-4"
    style="padding-top:20px; padding-bottom: 40px;background-image: url('./assets/img/cprtraining.png'); background-size:  cover;">
    <div class="container p-4">
        <div class="row">
            <div class="shadow-lg col-md-6 wow fadeInUp p-4" style="text-align:center !important; background:white;">
                <img src="./assets/img/cecpd.png" style="display: block;
              margin-left: auto;
              margin-right: auto;
              width:250px;
              height:200px;
              padding:20px;" />
                <p style="text-align: center !important; margin:0px; font-size:24px; color:#af1e46;">CECPD Approved
                    Pediatric CPR Classes Available</p><br>

                <p style="text-align: center;"><button class="btn btn-primary text-uppercase"
                        style="background:#1f8cc2 !important;text-align:center; border-radius:50px; font-size:20px; color:white; cursor:pointer;"
                        onclick="window.location='https://www.trainingsoftwaresupport.com/#/home/search?type=NF&month=1&location=NF&spanish=false';">CPR TRAINING
                        Registration</button></p>
                <hr />
                <p class="text-uppercase" style="color:#af1e46; margin-top:27px;">Click on the link above to view our
                    Class Schedules and to Register for a Class</p>

                <p style="font-size:10px;">There Are No Refunds For Any Reason.</p>
                <p style="font-size:10px;">CPR & First Aid Training Institute’s policy does NOT allowed children nor
                    infants in our training classes.</p>
                <p style="font-size:10px;">
                    No Late Entry. <br /><br />
                    You must give notice of your intent to reschedule no less than (24) hours prior to your class date
                    in order to be permitted to reschedule and attend our next available class. You can only reschedule
                    one time. A $45 rescheduling fee will be required before rescheduling a class.
                </p>
            </div>
            <div id="secondPage" class="shadow-lg col-md-6 p-4 wow fadeInUp text-center"
                style="background-image: url('./assets/img/bg1.jpg');">
                <p style="position:absolute; left:320px; top:150px; font-size:10px; font-weight: bold;">TM</p>
                <img class="align-self-center pt-4" src="./assets/img/logo.png" width="130px" alt="">
                <h1 class="text-left text-uppercase py-2" style="color:#af1e46;font-size:30px;">
                    CPR & First Aid Training Institute
                </h1>
                <p class="" style="font-size:17px;text-align:justify;">CPR & First Aid Training Institute is a Health
                    and Safety company serving the State of Oklahoma. It follows the safety
                    standards established by the U.S. Department of Labor & The American Heart Association guidelines
                    and is registered
                    as a Small Business, Women & Minority Owned.
                    <br>
                    <br> CPR & First Aid Training Institute provides OSHA Construction Safety Training (10 & 30 hrs.),
                    OSHA Bloodborne Pathogens, OSHA Forklift Safety, CPR (Cardio-Pulmonary Resuscitation), First Aid,
                    and AED (Automated External Defibrillator) training classes. CPR & First Aid Training Institute also
                    provides Bilingual training options for your convenience, including offering Command Spanish
                    training programs to professionals and the community.
                    <br>
                    <br> CPR & First Aid Training Institute is an approved DHS and CECPD - University of Oklahoma Early
                    Childhood Training Provider and proudly provides Pediatric CPR & First Aid training classes.

                </p>
            </div>
        </div>

    </div>
</div>
<!-- Por qué elegirnos -->
<div class="container-fluid"
    style="padding-top:60px; padding-bottom: 80px; background-image: url('./assets/img/bg1.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-md-5 text-center py-2" style=" font-size:20px;">
                <p class="text-center align-seld-middle pb-1">
                <p style="position:absolute; left:280px; top:153px; font-size:10px; font-weight: bold;">TM</p>
                <img class="align-self-center pt-4 mb-3" src="./assets/img/logo.png" width="130px" alt="">
                <p class="h2 text-center">
                    <b class="my-4 text-uppercase"
                        style="font-size:27px;font-family: 'Francois One', sans-serif; font-size:45px; color:#006699;">
                        Why Choose Us? </b>
                </p>
                <p class="wow fadeInUp text-justify" style="font-size:17px;">
                    <b>CPR & First Aid Training Institute</b> delivers high quality, hands-on health and safety training
                    to your business, organization, or family. We offer courses in Cardiopulmonary Resuscitation (CPR),
                    Automated External Defibrillator (AED), First
                    Aid, and a multitude of other personal and workplace safety trainings.<br><br>
                    Our staff is comprised of safety training instructors and medical professionals who are fully
                    trained and
                    provide safety-centric customer service.<br><br>
                    <b>CPR & First Aid Training Institute</b> is one of the few training providers that offer a
                    combination of on-site, bilingual training programs in the State of Oklahoma.

                    <br>
                </p>
                <a (click)="Contacto()"><button type="button" class=" my-4 btn lead text-uppercase"
                        style="font-size:20px; padding:10px 60px; background:#af1e46; color:white !important;"><i
                            class="fas fa-envelope"></i>
                        Contact Us</button></a>
            </div>
            <div class="col-md-7 rounded text-center pt-" style="font-size:19px; color:#fff;">
                <div class="row">
                    <div class=" col-12 cuadro col-md-5 rounded"
                        style="margin-left:20px; background:#1f8cc2; padding-top:20px !important; padding-bottom:20px !important; margin-left:20px; margin-top:25px !important;">
                        <img class="wow rotateIn" src="./assets/img/hand.png" alt="">
                        <h1 class="pt-4" style=" color:#fff; font-size: 27px !important;">Honesty</h1>
                        <p class="wow fadeInUp text-justify" style="font-size:16px;">We believe honesty and openness
                            with our customers and our employees is core to our company’s
                            success. We instill long-lasting confidence and customer loyalty by building relationships
                            based on trust.</p>
                    </div>
                    <div class="cuadro col-md-5 rounded"
                        style="background:#1f8cc2; padding-top:20px !important; padding-bottom:20px !important; margin-left:20px; margin-top:25px !important;">
                        <img class="wow rotateIn" src="./assets/img/group.png" alt="">
                        <h1 class="pt-4" style=" color:#fff; font-size: 27px !important;">Respect</h1>
                        <p class="wow fadeInUp text-justify" style="font-size:16.3px; word-spacing:-1px;">We are
                            committed to creating a respectful workplace and training atmosphere. We strive to treat all
                            our customers and employees with the utmost level of professionalism and respect.</p>
                    </div>
                    <div class="cuadro col-md-5 rounded"
                        style="margin-left:20px; background:#1f8cc2; padding-top:20px !important; padding-bottom:20px !important; margin-left:20px; margin-top:25px !important;">
                        <img class="wow rotateIn" src="./assets/img/thumbs-up.png" alt="">
                        <h1 class="pt-4 text-white" style=" color:#fff; font-size: 27px !important;">Integrity</h1>
                        <p class="wow fadeInUp text-justify" style="font-size:16px; word-spacing:-1px;">We regard
                            integrity as our company’s critical core value. We believe in putting our customer’s needs
                            before the needs of ourselves. We act according to moral principles that never compromise
                            our
                            integrity.</p>
                    </div>
                    <div class="cuadro col-md-5 rounded"
                        style="background:#1f8cc2; padding-top:20px !important; padding-bottom:20px !important; margin-left:20px; margin-top:25px !important;">
                        <img class="wow rotateIn" src="./assets/img/icono9.png" alt="">
                        <h1 class="pt-4" style=" color:#fff; font-size: 27px !important;">Certified Trainers</h1>
                        <p class="wow fadeInUp text-justify" style="font-size:16px; word-spacing:-1px;">We seek to offer
                            the industry’s best instructors who are certified to teach in their respective discipline.
                            Our instructors are highly knowledgeable professionals that are passionate and inspire
                            others to reach
                            their full potential.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Clientes-->
<div class="container-fluid py-4 text-center "
    style="padding-top:60px; padding-bottom: 60px; background-image: url('./assets/img/cprtraining.png'); background-size:  cover;">
    <h3 class="h1 text-center text-white wow fadeInUp my-4">
        <b>WHAT DO PEOPLE THINK ABOUT US?</b>
    </h3>
    <figure class="snip1533 hideme2  wow fadeInUp">
        <figcaption>
            <blockquote>
                <p>“The class was amazing, the instructors were very friendly and easy to follow, and I highly recommend
                    it!“</p>
            </blockquote>
            <h3>Dennis Louis S. </h3>
            <span class="fa fa-star checked" style="color: orange;"></span>
            <span class="fa fa-star checked" style="color: orange;"></span>
            <span class="fa fa-star checked" style="color: orange;"></span>
            <span class="fa fa-star checked" style="color: orange;"></span>
            <span class="fa fa-star checked" style="color: orange;"></span>
        </figcaption>
    </figure>
    <figure class="snip1533 hideme2  wow fadeInUp">
        <figcaption>
            <blockquote>
                <p>“I took their CPR class, and now I feel ready for any emergency anywhere and whenever.”</p>
            </blockquote>
            <h3>Michael L. Lee</h3>
            <span class="fa fa-star checked" style="color: orange;"></span>
            <span class="fa fa-star checked" style="color: orange;"></span>
            <span class="fa fa-star checked" style="color: orange;"></span>
            <span class="fa fa-star checked" style="color: orange;"></span>
            <span class="fa fa-star checked" style="color: orange;"></span>
        </figcaption>
    </figure>
    <figure class="snip1533 hideme2  wow fadeInUp">
        <figcaption>
            <blockquote>
                <p>"CPR & First Aid Training Institute trained my employees and I am very happy with the results, they
                    saved me a lot of money!"</p>
            </blockquote>
            <h3>Ken B. Johnson</h3>
            <span class="fa fa-star checked" style="color: orange;"></span>
            <span class="fa fa-star checked" style="color: orange;"></span>
            <span class="fa fa-star checked" style="color: orange;"></span>
            <span class="fa fa-star checked" style="color: orange;"></span>
            <span class="fa fa-star checked" style="color: orange;"></span>
        </figcaption>
    </figure>
    <div class="row d-flex justify-content-center">
        <div class="col-md-4" style="background:#af1e46;">
            <p class="h3 text-white my-3">We would like to hear from you!</p>
            <p class="text-white">Leave us a review:</p>
            <!--<a href="https://g.page/CprAndFirstAidTrainingInstitute/review?rc" class="mr-3"><button class="btn wow fadeInUp m-0 mb-3"
                style=" background:#1f8cc2;margin-top:40px;border:0; padding: 5px 10px; font-size:20px; color:white;">
                 Give your review 
            </button></a>-->
            
            <a href="https://g.page/CprAndFirstAidTrainingInstitute/review?rc" class="mr-3"><button class="btn wow fadeInUp m-0 mb-3"
                style=" background:#1f8cc2;margin-top:40px;border:0; padding: 5px 10px; font-size:20px; color:white;">
                <i class="fab fa-google"></i> Google Review
            </button></a>
            <button data-bs-toggle="modal" data-bs-target="#exampleModal" class="btn wow fadeInUp m-0 mb-3"
                    style=" background:#1f8cc2;margin-top:40px;border:0; padding: 5px 10px; font-size:20px; color:white;">
                    Regular Review
                </button><br>
            
            <p>
                <a (click)="allTestimonies()" href="alltestimonies#allTestimoniesList"
                    style="text-decoration:none; color:white;">
                    <span class="fa fa-star checked" style="color:orange;"></span> See More Testimonials <span
                        class="fa fa-star checked" style="color:orange;"></span>
                </a>
            </p>
        </div>
    </div>

</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Leave us a review</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Name</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder=""
                        [(ngModel)]="name" name="name">
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Keyword</label>
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder=""
                        [(ngModel)]="keyword" name="k">
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Review message</label>
                    <textarea class="form-control" name="message" id="exampleFormControlTextarea1" rows="3"
                        [(ngModel)]="message"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary" (click)="SendReview()">Submit</button>
            </div>
        </div>
    </div>
</div>