import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class footerComponent {

  constructor(private router: Router ) {}

  title = 'Website';
  EstatusAsociacion = '';
  Association: string = '';
  RespuestaAsociacion: boolean = false;

  contact(){
    this.router.navigate([`../contact`]);
  }
  allCourses(){
    this.router.navigate([`../allcourses`]);
  }
  home(){
    this.router.navigate([``]);
  }
}

