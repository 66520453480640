import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AllcoursesComponent } from './Modulos/Allcourses/Allcourses.component';
import { AlltestimoniesComponent } from './Modulos/Alltestimonies/Alltestimonies.component';
import { BloodborneComponent } from './Modulos/Bloodborne/Bloodborne.component';
import { ContactComponent } from './Modulos/Contact/Contact.component';
import { CprComponent } from './Modulos/Cpr/Cpr.component';
import { DeseaseComponent } from './Modulos/Desease/Desease.component';
import { ForkLiftComponent } from './Modulos/ForkLift/ForkLift.component';
import { InicioComponent } from './Modulos/Inicio/Inicio.component';
import { OshaComponent } from './Modulos/Osha/Osha.component';
import { RespiratoryComponent } from './Modulos/Respiratory/Respiratory.component';
const routes: Routes = [
  {path: '', component: InicioComponent},
  {path: 'osha', component: OshaComponent},
  {path: 'respiratory', component: RespiratoryComponent},
  {path: 'bloodborne', component: BloodborneComponent},
  {path: 'forklift', component: ForkLiftComponent},
  {path: 'cpr', component: CprComponent},
  {path: 'disease', component: DeseaseComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'allcourses', component: AllcoursesComponent},
  {path: 'alltestimonies', component: AlltestimoniesComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
