<div class="container-fluid "
  style="padding-top:20px; padding-bottom: 40px;background-image: url('./assets/img/bg1.jpg');">
  <div class="container ">
    <div class="row">
      <div class="col-md-12 my-2 wow fadeInUp">
        <h3 class="text-center text-uppercase"
          style="font-family: 'Francois One', sans-serif; font-size:37px; color:#af1e46;">OSHA Safety Training</h3>
      </div>
      <div class="col-md-7 wow fadeInUp text-justify"> 
        <p class="lead alert-danger px-2"
          style="width:100%; background:#006699 !important; color:white; border-radius:5px;">30-Hour OSHA Standards for
          the Construction Industry - OSHA 510</p>
        <p>This training class is a comprehensive safety program designed for anyone involved in the construction
          industry. Specifically devised for safety directors, foremen and managers, the program provides complete
          information on OSHA compliance issues.</p>
        <p class="lead alert-danger px-2"
          style="width:100%; background:#006699 !important; color:white; border-radius:5px;">30-Hour OSHA Standards for
          General Industry - OSHA 511</p>
        <p>This training class is a comprehensive safety program designed for anyone involved in General Industry.
          Specifically devised for safety directors and field supervisors, the program provides complete information on
          OSHA compliance issues.</p>
        <p class="lead alert-danger px-2"
          style="width:100%; background:#006699 !important; color:white; border-radius:5px;">10-Hour Construction
          Industry Safety and Outreach Program</p>
        <p>This training class is designed for construction workers as an ideal orientation to those who are new to the
          industry and as a reminder to those who have been working in the industry to the hazards associated with their
          work.</p>
        <p class="lead alert-danger px-2"
          style="width:100%; background:#006699 !important; color:white; border-radius:5px;">10-Hour General Industry
          Safety and Health Outreach Program</p>
        <p>This 10-hour program is intended to provide a wide range of training on General Industry safety and health to
          entry-level workers in General Industry. </p>


      </div>
      <div class="col-md-5 mt-2 wow fadeInUp">

        <!--Carousel Wrapper-->
        <div id="carousel-example-1z" class="carouselwrapper co carousel slide carousel-fade ml-4 pl-4" data-ride="carousel"
          style="margin-left:900px;">
          <div id="CarrouselOsha" class="carousel-inner" role="listbox">
            <div class="oshacarrousel carousel-item active" style="max-width:400px; border:10px solid #af1e46;">
              <img class="d-block w-80 img-fluid " id="oshaIMG" style="max-height: 350px;"
                src="./assets/img/shutterstock_1012548829-min.jpg" alt="Third slide">
            </div>
            <div class="oshacarrousel carousel-item" style="max-width:400px; border:10px solid #af1e46;">
              <img class="d-block w-80 img-fluid" id="oshaIMG" style="max-height: 350px;"
                src="./assets/img/shutterstock_1549292777-min.jpg" alt="First slide">
            </div>
            <div class="oshacarrousel carousel-item" style="max-width:400px; border:10px solid #af1e46;">
              <img class="d-block w-80 img-fluid" id="oshaIMG" style="max-height: 350px;"
                src="./assets/img/shutterstock_665870128-min.jpg" alt="Third slide">
            </div>
            <div class="oshacarrousel carousel-item" style="max-width:400px; border:10px solid #af1e46;">
              <img class="d-block w-80 img-fluid" id="oshaIMG" style="max-height:350px;"
                src="./assets/img/shutterstock_1017508450-min.jpg" alt="Third slide">
            </div>
            <div class="oshacarrousel carousel-item" style="max-width:400px; border:10px solid #af1e46;">
              <img class="d-block w-80 img-fluid" id="oshaIMG" style="max-height: 350px;"
                src="./assets/img/shutterstock_1312267823-min.jpg" alt="Third slide">
            </div>
          </div>
          <a class="carousel-control-prev" href="#carousel-example-1z" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carousel-example-1z" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 wow fadeInUp">
        <a href="https://www.trainingsoftwaresupport.com/#/home/search?type=NF&month=1&location=NF&spanish=false" target="_Blank"><button class="btn btn-primary"
            style="font-size:20px; background:#af1e46 !important;"><i class="fas fa-phone-square"></i> Register for OSHA
            Safety Training classes now!</button></a>
      </div>
    </div>
  </div>
</div>