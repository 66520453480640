<div class="container-fluid "
  style="padding-top:20px; padding-bottom: 40px;background-image: url('./assets/img/bg1.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-md-12 my-2 wow fadeInUp">
        <h1 class="text-center text-uppercase pb-4"
          style="font-family: 'Francois One', sans-serif; font-size:37px; color:#af1e46;">
          CPR & First Aid Training
        </h1>
      </div>
      <div class="col-md-6 wow fadeInUp text-justify">
        <div class="text-center pb-4" style="margin-top:-20px;">
          <img class="text-center img-fluid" src="./assets/img/cecpd.png" alt="" width="170px;">
          <p class="text-center">Formal Class Training Approved by CEDCPD</p>
          <p class="lead text-center my-2 p-1 text-uppercase" style="background:#006699; color:white;">ONSITE TRAINING
            Available for your convenience</p>

        </div>
        <p class="lead alert-danger px-2"
          style="max-width:500px; background:#006699 !important; color:white; border-radius:5px;">CPR & First Aid
          Training</p>

        <p>We offer many certified and approved training options to meet the needs in any industry. Classes are
          available at your place of business, home, or a meeting place convenient to all. The atmosphere of
          every class provided is relaxed to help promote a positive learning experience. </p>
      </div>
      <div class="col-md-6 my-2 wow fadeInUp">
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img class="d-block w-100 img-fuid" id="imgDesease" src="./assets/img/shutterstock_704399263-min.jpg" alt="First slide"
                style="max-height:350px;">
            </div>
            <div class="carousel-item ">
              <img class="d-block w-100" id="imgDesease" src="./assets/img/shutterstock_1017508450-min.jpg" alt="First slide"
                style="max-height:350px;">
            </div>
            <div class="carousel-item ">
              <img class="d-block w-100" id="imgDesease" src="./assets/img/shutterstock_263151092-min.jpg" alt="First slide"
                style="max-height:350px;">
            </div>
            <div class="carousel-item ">
              <img class="d-block w-100" id="imgDesease" src="./assets/img/shutterstock_513588181-min.jpg" alt="First slide"
                style="max-height:350px;">
            </div>
            <div class="carousel-item ">
              <img class="d-block w-100" id="imgDesease" src="./assets/img/shutterstock_472075360-min.jpg" alt="First slide"
                style="max-height:350px;">
            </div>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 wow fadeInUp text-justify">
        <p class="lead alert-danger px-2"
          style="max-width:500px; background:#006699 !important; color:white; border-radius:5px;">CPR, First Aid, &amp;
          AED Training</p>

        This hands-on training class is designed for students who need Adult CPR and First Aid. This class addresses
        Primary Assessment,
        CPR, and Abdominal Thrust skills during Choking Emergencies. During our First Aid component students
        will be able to respond to emergency situations such as bleeding, cuts, scrapes, as well as how
        to treat muscle and bone injuries. In addition, our students will learn how to provide care for
        victims in cardiac arrest by using an Automated External Defibrillator (AED).

      </div>
    </div>
    <div class="row">
      <div class="col-md-12 wow fadeInUp text-justify">
        <p class="lead alert-danger px-2"
          style="max-width:500px; background:#006699 !important; color:white; border-radius:5px;">Adult CPR Training</p>

        <p>This hands-on training class is designed for students who need Adult CPR. This class addresses Primary
          Assessment, CPR, and
          Abdominal Thrust skills during Choking Emergencies.</p>
        <p class="lead alert-danger px-2"
          style="max-width:500px; background:#006699 !important; color:white; border-radius:5px;">Pediatric CPR &amp;
          First Aid Training</p>

        This class is designed to provide students with the knowledge and skills to respond to medical emergencies while
        working
        with children. Topics include; Primary Assessment, Child and Infant CPR, Choking skills, First
        Aid basic techniques and Secondary Assessment. This class follows the DHS standards by the State
        of Oklahoma and Texas.

      </div>
    </div>
    <div class="row">
      <div class="col-md-12 wow fadeInUp p-0"  class="btnBuy">
        <a href="https://www.trainingsoftwaresupport.com/#/home/search?type=NF&month=1&location=NF&spanish=false" target="_Blank"><button class="btn btn-primary"
            style="font-size:20px; background:#af1e46 !important;"><i class="fas fa-phone-square"></i> Register for cpr
            & first aid Training classes now!</button></a>
      </div>
    </div>
  </div>
</div>